import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Sidebar/Header";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ViewVendorPay.css";

const VendorPayment = () => {
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hh = String(now.getHours()).padStart(2, "0");
    const mm = String(now.getMinutes()).padStart(2, "0");
    return `${hh}:${mm}`;
  };

  const initialFormData = {
    selectedVendor: "",
    selectedEvent: null, // Store the selected event object
    fname: "",
    event_name: "",
    date: getCurrentDate(),
    time: getCurrentTime(),
    bankAccount_Name: "",
    paid_amt: "",
    advance_payment: 0,
    rem_amt: 0,
    description: "",
    total_price: 0,
    paid_amount: 0,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [vendors, setVendors] = useState([]);
  const [events, setEvents] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [bankNames, setBankNames] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedBank, setSelectedBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get("https://backend.ssdpune.org/api/addvendor");
        setVendors(response.data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    fetchVendors();
  }, []);

  useEffect(() => {
    const fetchEventsForVendor = async (selectedVendorId) => {
      try {
        const response = await axios.get(
          `https://backend.ssdpune.org/api/customerquotationinfo/${selectedVendorId}`
        );
        if (response.status === 200) {
          console.log(response.data);
          setEvents(response.data);
        }
      } catch (error) {
        setEvents([]);
        console.error("Error fetching events for vendor:", error);
      }
    };

    if (formData.selectedVendor) {
      fetchEventsForVendor(formData.selectedVendor);
    }
  }, [formData.selectedVendor]);

  const handleVendorChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      selectedVendor: value,
      selectedEvent: null,
    }));
  };

  const handleEventChange = (event) => {
    const selectedEventId = event.target.value;
    const selectedEvent = events.find(
      (event) => event.event_name === selectedEventId
    );
    const paidAmount = selectedEvent ? selectedEvent.paid_amount : 0;
    const totalAmount = selectedEvent ? selectedEvent.total_price : 0;
    const remainingAmount = totalAmount - paidAmount;

    setFormData((prevData) => ({
      ...prevData,
      selectedEvent: selectedEvent,
      paid_amt: paidAmount,
      rem_amt: remainingAmount,
      total_price: totalAmount,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.selectedVendor)
      formErrors.selectedVendor = "Vendor is mandatory";
    if (!formData.selectedEvent) formErrors.selectedEvent = "Event is mandatory";
    if (!formData.date) formErrors.date = "Date is mandatory";
    if (!formData.paid_amt) formErrors.paid_amt = "Paid amount is mandatory";
    if (!formData.advance_payment)
      formErrors.advance_payment = "Advance payment is mandatory";
    if (!selectedBank) formErrors.bankAccount_Name = "Bank selection is mandatory";
    if (!accountNumber) formErrors.accountNumber = "Account number is mandatory";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        // First, update the paid price with PATCH
        const patchResponse = await axios.patch(
          `https://backend.ssdpune.org/api/customerquotationinfo/stock/${formData.selectedVendor}/${formData.selectedEvent._id}`,
          {
            paidPrice: formData.advance_payment,
          }
        );

        if (patchResponse.status === 200) {
          console.log("Paid price updated successfully");

          // Then, submit the vendor payment form
          const response = await axios.post(
            "https://backend.ssdpune.org/api/vendorpayment",
            {
              fname: formData.selectedVendor,
              event_name: formData.selectedEvent.event_name, // Access event name from selectedEvent
              date: formData.date,
              time: formData.time,
              bankAccount_Name: selectedBank,
              bank_Account_Number: accountNumber,
              paid_amt: formData.paid_amt,
              advance_payment: formData.advance_payment,
              rem_amt: formData.rem_amt,
              description: formData.description,
              total_price: formData.total_price, // Include total price in submission
              Account_Holder_Name: accountHolderName,
              account_number: accountNumber,
            }
          );

          if (response.status === 200) {
            window.alert("Vendor Payment successfully!");
            setFormData(initialFormData);
            setSelectedBank("");
            setAccountNumber("");
            setAccountHolderName("");
          }
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };

  const handleAdvancePaymentChange = (event) => {
    const newAdvancePayment = event.target.value;

    if (
      !isNaN(newAdvancePayment) &&
      newAdvancePayment >= 0 &&
      newAdvancePayment <= parseInt(formData.paid_amt)
    ) {
      setFormData((prevData) => ({
        ...prevData,
        advance_payment: newAdvancePayment,
      }));
    }
  };

  const handleBankSelect = (event) => {
    const selectedBankName = event.target.value;
    setSelectedBank(selectedBankName);

    const selectedBankObj = bankNames.find(
      (bank) => bank.Bank_Name === selectedBankName
    );

    if (selectedBankObj) {
      setAccountNumber(selectedBankObj.Account_Number);
      setAccountHolderName(selectedBankObj.Account_Holder_Name);
    } else {
      setAccountNumber("");
      setAccountHolderName("");
    }
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="md:h-[80vh] h-[80vh] md:w-[50%]">
          <Form onSubmit={handleSubmit}>
            <div className="flex">
              <Link to={"/eventmanager/vendorpayment"}>
                <button className="btn btn-primary mr-4 mb-4">
                  Add Vendor Payment
                </button>
              </Link>
              <Link to={"/eventmanager/viewvendorpayment"}>
                <button className="btn btn-primary mr-4 mb-4">
                  View Vendor Payment Details
                </button>
              </Link>
            </div>

            <h2 className="text-[30px] pl-[1em]">Vendor Payment</h2>
            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="SelectVendor">
                  <Form.Label>
                    Select Vendor:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Select
                    className={`w-full py-2 pl-3 pr-10 border-gray-300 rounded-2xl focus:outline-none focus:ring focus:ring-indigo-400 focus:border-indigo-400 ${
                      errors.selectedVendor ? "is-invalid" : ""
                    }`}
                    aria-label="Select Vendor"
                    name="selectedVendor"
                    onChange={handleVendorChange}
                    value={formData.selectedVendor}
                  >
                    <option>Select Vendor</option>
                    {vendors.map((vendor) => (
                      <option key={vendor.Vendor_Name} value={vendor._id}>
                        {vendor.Vendor_Name}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.selectedVendor && (
                    <div className="invalid-feedback">{errors.selectedVendor}</div>
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="SelectEvent">
                  <Form.Label>
                    Select Event:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Select
                    className={`w-full py-2 pl-3 pr-10 border-gray-300 rounded-2xl focus:outline-none focus:ring focus:ring-indigo-400 focus:border-indigo-400 ${
                      errors.selectedEvent ? "is-invalid" : ""
                    }`}
                    aria-label="Select Event"
                    name="event_name"
                    onChange={handleEventChange}
                    value={formData.selectedEvent ? formData.selectedEvent._id : ""}
                  >
                    <option>Select Event</option>
                    {events.map((event) => (
                      <option key={event._id} value={event.event_name}>
                        {event.event_name}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.selectedEvent && (
                    <div className="invalid-feedback">{errors.selectedEvent}</div>
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="date">
                  <Form.Label>
                    Date:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col px-5">
                <Form.Group controlId="time">
                  <Form.Label>
                    Time:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    isInvalid={!!errors.time}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.time}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="SelectBank">
                  <Form.Label>
                    Select Bank:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Select
                    className={`w-full py-2 pl-3 pr-10 border-gray-300 rounded-2xl focus:outline-none focus:ring focus:ring-indigo-400 focus:border-indigo-400 ${
                      errors.bankAccount_Name ? "is-invalid" : ""
                    }`}
                    aria-label="Select Bank"
                    name="bankAccount_Name"
                    value={selectedBank}
                    onChange={handleBankSelect}
                  >
                    <option>Select Bank</option>
                    {bankNames.map((bank) => (
                      <option key={bank._id} value={bank.Bank_Name}>
                        {bank.Bank_Name}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.bankAccount_Name && (
                    <div className="invalid-feedback">{errors.bankAccount_Name}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col px-5">
                <Form.Group controlId="bankAccount_Name">
                  <Form.Label>Bank Account Holder Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAccount_Name"
                    value={accountHolderName}
                    readOnly
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="account_number">
                  <Form.Label>Account Number:</Form.Label>
                  <Form.Control
                    type="text"
                    name="account_number"
                    value={accountNumber}
                    readOnly
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="total_price">
                  <Form.Label>Total Price:</Form.Label>
                  <Form.Control
                    type="number"
                    name="total_price"
                    value={formData.total_price}
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className="col px-5">
                <Form.Group controlId="paid_amt">
                  <Form.Label>
                    Paid Amount:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="paid_amt"
                    value={formData.paid_amt}
                    readOnly
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="advance_payment">
                  <Form.Label>
                    Advance Payment:<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="advance_payment"
                    value={formData.advance_payment}
                    onChange={handleAdvancePaymentChange}
                    isInvalid={!!errors.advance_payment}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.advance_payment}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col px-5">
                <Form.Group controlId="rem_amt">
                  <Form.Label>Remaining Amount:</Form.Label>
                  <Form.Control
                    type="number"
                    name="rem_amt"
                    value={formData.rem_amt}
                    readOnly
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col px-5">
                <Form.Group controlId="description">
                  <Form.Label>Description:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <Button type="submit" className="btn btn-primary mt-3">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default VendorPayment;
