import React, { useState, useEffect } from "react";
import Header from "../Sidebar/Header";
import { Link } from 'react-router-dom';
import axios from "axios";
import { green } from "@mui/material/colors";

const ViewTaskPage = () => {
  const [tasks, setTasks] = useState([]);
  const managerId = localStorage.getItem('managerId');

  const fetchTasks = async () => {
    try {
      const response = await fetch(`https://backend.ssdpune.org/api/managertask/manager/${managerId}`);
      const data = await response.json();
      setTasks(data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [managerId]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const updateHandle = async (id) => {
    try {
      const data = {};
      const res = await axios.patch(`https://backend.ssdpune.org/api/managertask/status/${id}`, data);
      
      if (res.status === 200) {
        alert('Task Status Updated.');
        fetchTasks(); // Refresh tasks to reflect the update
      } else {
        console.error('Failed to update task:', res.data.message);
      }
    } catch (error) {
      console.error("Error updating task:", error); // Log any error
    }
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="md:h-[80vh] h-[80vh] md:mt-0 md:w-[80%] min-w-8 mx-4">
          <div className="flex">
            <Link to={'/eventmanager/viewtask'}>
              <button className="btn btn-primary mr-4 mb-4">View Update Tasks</button>
            </Link>
          </div>
          <h1 className="text-[30px]">View Tasks</h1>
          <div className="overflow-y-auto h-[70vh] md:mt-0 w-full row">
            {tasks.map((task) => (
              <div key={task._id} className="col-md-4 mb-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title"><strong>{task.task}</strong></h5>
                    <p className="card-text">
                      <strong>Updated task:</strong> {task.manager_Name}
                      <br />
                      <strong>Date:</strong> {task.date}
                      <br />
                      <strong>Status:</strong> <span className={`${task.status === 'pending' ? 'text-red-500' : 'text-green-600'} font-semibold `}>{capitalizeFirstLetter(task.status)}</span>
                    </p>
                    {task.status === 'pending' && (
                      <button
                        className="bg-green-500 text-white p-2 rounded-md mt-2"
                        onClick={() => updateHandle(task._id)}
                      >
                        Click after Completed
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTaskPage;
