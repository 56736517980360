import React from "react";
import Header from "../Sidebar/Header";

const CostingForm = () => {
  return (
    <>
      <Header />
    </>
  );
};

export default CostingForm;
