import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { EventManagerApp } from './EventManager/App';
import NewLandingPage from "./Admin/components/NewLandingPage/LandingPage/LandingPage";
import PublicRoute from "./Admin/components/ProtectedRoute/PublicRoute";
import { AdminApp } from './Admin/App';
function App() {
  return (
    <>
      <Router>
        <Routes>            
          <Route path="/" element={<PublicRoute element={<NewLandingPage />} restrictedPath="/admin/dashboard" />} />
          <Route path='/admin/*' element={<AdminApp />} />
          <Route path='/eventmanager/*' element={<EventManagerApp />} />
        </Routes>
      </Router>
    </>
  )
}
export default App;